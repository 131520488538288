var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增代金劵"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"活动名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入活动名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入活动名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"活动类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['activity_type', {
            rules: [{ required: true, message: '请选择活动类型' }]
          }]),expression:"['activity_type', {\n            rules: [{ required: true, message: '请选择活动类型' }]\n          }]"}]},[_c('a-select-option',{key:"registered_benefits",attrs:{"value":"registered_benefits"}},[_vm._v(" 注册福利 ")]),_c('a-select-option',{key:"information_improvement",attrs:{"value":"complete_family_information_benefits"}},[_vm._v(" 完善家属信息福利 ")])],1)],1),_c('a-form-item',{attrs:{"label":"活动截止日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['end_time', {
            rules: [
              { required: true, message: "请选择活动截止日期" }
            ]
          }]),expression:"['end_time', {\n            rules: [\n              { required: true, message: `请选择活动截止日期` }\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm:ss","show-time":""}})],1),_c('a-form-item',{attrs:{"label":"代金券金额(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['amount', {
            rules: [
              { required: true, message: '请输入代金券金额' } ]
          }]),expression:"['amount', {\n            rules: [\n              { required: true, message: '请输入代金券金额' },\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":0.01,"max":10000000,"step":0.01,"precision":2}})],1),_c('a-form-item',{attrs:{"label":"使用条件"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['use_type', {
            rules: [{ required: true, message: '请选择使用条件' }]
          }]),expression:"['use_type', {\n            rules: [{ required: true, message: '请选择使用条件' }]\n          }]"}],attrs:{"mode":"multiple","loading":_vm.voucherUseTypeOption.length === 0}},_vm._l((_vm.voucherUseTypeOption),function(type){return _c('a-select-option',{key:type.value,attrs:{"value":type.value}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"代金劵有效期"}},[_c('div',[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['period_type', {
              rules: [{ required: true, message: '请选择代金劵有效期' }]
            }]),expression:"['period_type', {\n              rules: [{ required: true, message: '请选择代金劵有效期' }]\n            }]"}],on:{"change":_vm.handEffectiveType}},[_c('a-radio',{attrs:{"value":"indefinite"}},[_vm._v("无限期")]),_c('a-radio',{attrs:{"value":"fixed_length"}},[_vm._v("固定时长")]),_c('a-radio',{attrs:{"value":"fixed_end"}},[_vm._v("固定期限")])],1)],1),(_vm.effectiveType && _vm.effectiveType === 'fixed_length')?_c('div',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['period_amount', {
              rules: [
                { required: true, message: '请输入代金劵有效期' },
                {validator: _vm.checkEndTime}
              ]
            }]),expression:"['period_amount', {\n              rules: [\n                { required: true, message: '请输入代金劵有效期' },\n                {validator: checkEndTime}\n              ]\n            }]"}],staticStyle:{"width":"60%"},attrs:{"min":0.1,"max":10000000,"step":0.1,"precision":1}}),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['period_unit', {
              rules: [{ required: true, message: '请选择代金劵有效期' },
                      {validator: _vm.checkUnit}]
            }]),expression:"['period_unit', {\n              rules: [{ required: true, message: '请选择代金劵有效期' },\n                      {validator: checkUnit}]\n            }]"}],staticStyle:{"width":"30%","margin":"0 10px"}},[_c('a-select-option',{key:"hour",attrs:{"value":"hour"}},[_vm._v(" 小时 ")]),_c('a-select-option',{key:"day",attrs:{"value":"day"}},[_vm._v(" 天 ")])],1)],1):_vm._e(),(_vm.effectiveType && _vm.effectiveType === 'fixed_end')?_c('div',[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['period_end_time', {
              rules: [
                { required: true, message: "请选择代金劵有效期" },
                { validator: _vm.compareToEndTime }
              ]
            }]),expression:"['period_end_time', {\n              rules: [\n                { required: true, message: `请选择代金劵有效期` },\n                { validator: compareToEndTime }\n              ]\n            }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm:ss","show-time":""},on:{"blur":_vm.handleConfirmBlur}})],1):_vm._e()]),_c('a-form-item',{attrs:{"label":"使用规则"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['rule_content', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入使用规则' },
              { max: 1000, message: '最多1000个字符' } ]
          }]),expression:"['rule_content', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入使用规则' },\n              { max: 1000, message: '最多1000个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }